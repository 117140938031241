var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fluid",attrs:{"id":"requestList"}},[(_vm.isAdmin)?_c('div',[_c('div',{staticClass:"request-list-div"},[_c('v-card',{staticClass:"px-5 py-3"},[_c('v-row',[_c('v-col',{staticClass:"d-flex",staticStyle:{"position":"relative","padding":"0 20px","text-align":"center","margin-top":"20px"},attrs:{"cols":"12"}},[_c('h3',{staticStyle:{"font-weight":"bold","margin":"auto"}},[_vm._v(" ARCHIVE MANAGEMENT SYSTEM ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{staticClass:"request-list-toolbar-1",staticStyle:{"height":"60px","border-radius":"10px 10px 0 0"},attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("List Dokumen Keluar")]),_c('v-divider',{staticClass:"mx-6",attrs:{"inset":"","vertical":""}}),_c('div',{staticStyle:{"width":"150px"}},[_c('v-select',{staticStyle:{"position":"relative","top":"15px","margin-left":"10px","font-size":"12px"},attrs:{"disabled":_vm.selectedData.length === 0,"label":"Action","items":[
                    { id: 0, name: '' },
                    { id: 1, name: 'Edit' },
                    { id: 2, name: 'Hapus' }
                  ],"item-text":"name","item-value":"id","outlined":"","return-id":"","dense":""},on:{"change":_vm.action},model:{value:(_vm.actionValue),callback:function ($$v) {_vm.actionValue=$$v},expression:"actionValue"}})],1),_c('div',{staticClass:"request-list-toolbar-div"},[_c('v-text-field',{staticStyle:{"position":"relative","top":"15px","width":"10px"},attrs:{"label":"Search here","type":"search","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchEnter($event)}},model:{value:(_vm.paramAPI.keyword),callback:function ($$v) {_vm.$set(_vm.paramAPI, "keyword", $$v)},expression:"paramAPI.keyword"}})],1),_c('div',{staticStyle:{"margin-left":"10px"}},[_c('v-btn',{staticClass:"white--text",attrs:{"elevation":"2","tile":"","small":"","color":"black"},on:{"click":_vm.release}},[_vm._v(" Keluarkan ")])],1)],1),_c('v-toolbar',{staticClass:"request-list-toolbar-2",staticStyle:{"height":"60px","border-radius":"10px 10px 0 0","border-bottom":"1px solid #e0e0e0"},attrs:{"flat":""}},[_c('v-text-field',{staticStyle:{"position":"relative","top":"15px","width":"10px"},attrs:{"label":"Cari Dokumen","type":"search","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchEnter($event)}},model:{value:(_vm.paramAPI.keyword),callback:function ($$v) {_vm.$set(_vm.paramAPI, "keyword", $$v)},expression:"paramAPI.keyword"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.result,"items-per-page":10,"loading":_vm.loading,"show-select":"","item-key":"id","single-select":true},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.loan_start_date",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.loan_start_date))+" ")])]}},{key:"item.loan_end_date",fn:function(ref){
                  var item = ref.item;
return [_c('td',{staticClass:"arch-expDate"},[_c('div',{staticClass:"arch-exp-date"},[_c('div',{staticStyle:{"width":"120px","padding":"0 5px"}},[_c('p',{staticStyle:{"margin":"0"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.loan_end_date))+" ")])]),(item.status !== null)?_c('div',{staticClass:"arch-exp-date-div1"},[(_vm.countdown(item.loan_end_date) <= '7')?_c('div',{staticClass:"arch-exp-date-notif"},[(item.status.id === 2)?_c('div',{staticClass:"exp-date-notif-div"},[(_vm.countdown(item.loan_end_date) > '0')?_c('v-chip',{staticClass:"exp-date-notif-chip1",attrs:{"small":"","color":"orange","text-color":"white"}},[_vm._v(" Done in "+_vm._s(_vm.countdown(item.loan_end_date))+" days ")]):(_vm.countdown(item.loan_end_date) < '0')?_c('v-chip',{staticClass:"exp-date-notif-chip2",attrs:{"small":"","color":"red","text-color":"white"}},[_c('p',{staticClass:"date-notif-chip2-p"},[_vm._v(" exceeded ")])]):_c('v-chip',{staticClass:"exp-date-notif-chip2",attrs:{"small":"","color":"pink","text-color":"white"}},[_c('p',{staticClass:"date-notif-chip2-p"},[_vm._v("Done")])])],1):_vm._e()]):_vm._e()]):_vm._e()])])]}},{key:"item.pic_name",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(item.doc.document_type_id === 2 ? item.employee.name : item.pic_name)+" ")])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [(item.status !== null)?_c('div',[_c('div',{style:(("padding: 0; display: flex; flex-direction: row;color:" + (_vm.status(
                        item.status
                      ))))},[_vm._v(" "+_vm._s(item.status.id !== 9 ? item.status.name : 'Approved')+" ")])]):_vm._e()]}}],null,true),model:{value:(_vm.selectedData),callback:function ($$v) {_vm.selectedData=$$v},expression:"selectedData"}})],1),_c('div',{staticClass:"pr-items-add-new-line"},[_c('EditDocument',{attrs:{"param":{
                items: _vm.itemEdit
              }},on:{"loadPage":_vm.initDataTable}})],1)],1)],1)],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }