<template>
  <div class="fluid" id="requestList">
    <div v-if="isAdmin">
      <div class="request-list-div">
        <v-card class="px-5 py-3">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h3 style="font-weight:bold;margin:auto;">
                ARCHIVE MANAGEMENT SYSTEM
              </h3>
            </v-col>
            <v-col cols="12">
              <v-toolbar
                class="request-list-toolbar-1"
                flat
                style="height: 60px; border-radius: 10px 10px 0 0"
              >
                <v-toolbar-title>List Dokumen Keluar</v-toolbar-title>
                <v-divider class="mx-6" inset vertical></v-divider>
                <div style="width: 150px">
                  <v-select
                    :disabled="selectedData.length === 0"
                    label="Action"
                    style="
                      position: relative;
                      top: 15px;
                      margin-left: 10px;
                      font-size: 12px;
                    "
                    v-model="actionValue"
                    :items="[
                      { id: 0, name: '' },
                      { id: 1, name: 'Edit' },
                      { id: 2, name: 'Hapus' }
                    ]"
                    item-text="name"
                    item-value="id"
                    outlined
                    return-id
                    dense
                    @change="action"
                  ></v-select>
                </div>
                <div class="request-list-toolbar-div">
                  <v-text-field
                    v-model="paramAPI.keyword"
                    label="Search here"
                    type="search"
                    outlined
                    dense
                    @keyup.enter="searchEnter"
                    style="position: relative; top: 15px; width: 10px"
                  ></v-text-field>
                  <!-- <div style="width: 120px">
                    <v-select
                      label="Status"
                      style="
                        position: relative;
                        top: 15px;
                        margin-left: 10px;
                        font-size: 12px;
                      "
                      v-model="paramAPI.status"
                      :items="[
                        { id: '', name: 'All' },
                        { id: 0, name: 'Need Approval' },
                        { id: 2, name: 'Loaned' },
                        { id: 8, name: 'Returned' },
                        { id: 9, name: 'Granted Download' }
                      ]"
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      dense
                    ></v-select>
                  </div> -->
                </div>
                <div style="margin-left: 10px">
                  <v-btn
                    @click="release"
                    elevation="2"
                    tile
                    small
                    color="black"
                    class="white--text"
                  >
                    Keluarkan
                  </v-btn>
                </div>
              </v-toolbar>
              <v-toolbar
                class="request-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Cari Dokumen"
                  type="search"
                  outlined
                  dense
                  @keyup.enter="searchEnter"
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                v-model="selectedData"
                class="elevation-1"
                style="cursor: pointer"
                :headers="headers"
                :items="result"
                :items-per-page="10"
                :loading="loading"
                @click:row="rowClick"
                show-select
                item-key="id"
                :single-select="true"
              >
                <template v-slot:[`item.loan_start_date`]="{ item }">
                  <div style="padding: 0; display: flex; flex-direction: row">
                    {{ convertDate(item.loan_start_date) }}
                  </div>
                </template>
                <template v-slot:[`item.loan_end_date`]="{ item }">
                  <!-- <div style="padding: 0; display: flex; flex-direction: row">
                    {{ convertDate(item.loan_end_date) }}
                  </div> -->
                  <td class="arch-expDate">
                    <div class="arch-exp-date">
                      <div style="width: 120px; padding: 0 5px">
                        <p style="margin: 0">
                          {{ convertDate(item.loan_end_date) }}
                        </p>
                      </div>
                      <div
                        v-if="item.status !== null"
                        class="arch-exp-date-div1"
                      >
                        <div
                          class="arch-exp-date-notif"
                          v-if="countdown(item.loan_end_date) <= '7'"
                        >
                          <div
                            v-if="item.status.id === 2"
                            class="exp-date-notif-div"
                          >
                            <v-chip
                              class="exp-date-notif-chip1"
                              v-if="countdown(item.loan_end_date) > '0'"
                              small
                              color="orange"
                              text-color="white"
                            >
                              Done in
                              {{ countdown(item.loan_end_date) }} days
                            </v-chip>
                            <v-chip
                              class="exp-date-notif-chip2"
                              v-else-if="countdown(item.loan_end_date) < '0'"
                              small
                              color="red"
                              text-color="white"
                            >
                              <p class="date-notif-chip2-p">
                                exceeded
                              </p>
                            </v-chip>
                            <v-chip
                              class="exp-date-notif-chip2"
                              v-else
                              small
                              color="pink"
                              text-color="white"
                            >
                              <p class="date-notif-chip2-p">Done</p>
                            </v-chip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </template>
                <template v-slot:[`item.pic_name`]="{ item }">
                  <div style="padding: 0; display: flex; flex-direction: row">
                    {{
                      item.doc.document_type_id === 2
                        ? item.employee.name
                        : item.pic_name
                    }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div v-if="item.status !== null">
                    <!-- <div
                      v-if="item.status.id === 9"
                      style="padding: 0; display: flex; flex-direction: row"
                    >
                      <p
                        style="margin:0; width:50% width:100%;  font-size:14px; "
                      >
                        <span
                          class="docSpanDownload green--text"
                          @click.stop="downloadSoftCopy(item)"
                          >Download
                          <v-icon color="green" small
                            >mdi-download</v-icon
                          ></span
                        >
                      </p>
                    </div> -->
                    <div
                      :style="
                        `padding: 0; display: flex; flex-direction: row;color:${status(
                          item.status
                        )}`
                      "
                    >
                      {{ item.status.id !== 9 ? item.status.name : 'Approved' }}
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-col>

            <div class="pr-items-add-new-line">
              <EditDocument
                :param="{
                  items: itemEdit
                }"
                @loadPage="initDataTable"
              />
            </div>
          </v-row>
        </v-card>
      </div>
    </div>
    <!-- <div v-else>
      <UserView />
    </div> -->
  </div>
</template>
<script>
import axios from 'axios'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  components: {
    EditDocument: () =>
      import('../../../components/archive-management/release/Edit')
    // UserView: () =>
    //   import('../../../components/archive-management/loaning/UserView.vue')
  },
  data: () => ({
    eSanqua: buildType.apiURL('esanqua'),
    download: buildType.apiURL('download'),
    build: process.env.VUE_APP_BUILD_TYPE,
    isAdmin: false,
    // menu_key: process.env.VUE_APP_BUILD_TYPE === 'development' ? 30 : 32,
    menu_key: process.env.VUE_APP_BUILD_TYPE === 'development' ? 32 : 32,
    permission: {},
    application: {
      id: 14,
      name: 'eArchive'
    },
    paramAPI: {
      keyword: '',
      offset: 0,
      limit: 1000,
      status: '11'
    },
    actionValue: 1,
    headers: [
      {
        text: 'No. Pengeluaran',
        value: 'loan_number',
        align: 'left',
        sortable: true
      },
      {
        text: 'PIC',
        value: 'pic_name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Nama Dokumen',
        value: 'doc.name',
        align: 'left',
        sortable: true
      },
      {
        text: 'No. Dokumen',
        value: 'doc.doc_no',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl. Dikeluarkan',
        value: 'loan_end_date',
        align: 'left',
        sortable: true
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: true
      }
    ],
    result: [],
    selectedData: [],
    loading: false,
    itemEdit: null
  }),
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  watch: {
    // 'paramAPI.status'() {
    //   this.initDataTable()
    // }
  },
  created() {
    // id 22 Umum (prod) | id 21 (dev)
    // id 28 Admin SOP | id 28 (dev)
    // const a = this.build === 'development' ? '19' : '20' // id 20 Admin Legal (prod) | id 20 (dev)
    // const b = this.build === 'development' ? '20' : '21' // id 21 DMS - Approver (prod) | id 19 (dev)
    const a = this.build === 'development' ? '20' : '20'
    const b = this.build === 'development' ? '19' : '21'
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            this.setPermission(result.data)
            if (arr[i].id === '1' || arr[i].id === a || arr[i].id === b) {
              this.isAdmin = true
              this.initDataTable()
            } else {
              this.isAdmin = false
            }
          })
          .catch(error => {
            if (error) {
              this.isAdmin = false
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
            }
          })
      } else {
        this.isAdmin = false
        this.permission = {
          read_perm: 0
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'setLoaningDocId',
      'setLoaningDocumentName',
      'setPermission'
    ]),
    ...mapActions(['dropdownDocStatus', 'menu_access']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    action(event) {
      switch (event) {
        case 1:
          alert('Cooming Soon')
          // if (this.selectedData[0].status !== null) {
          //   if (this.selectedData[0].status.id === 2) {
          //     this.dropdownDocStatus()
          //     this.itemEdit = {
          //       id: this.selectedData[0].doc.id,
          //       loaned_id: this.selectedData[0].id
          //     }
          //     setTimeout(() => {
          //       document.getElementById('archiveReleaseEdit').click()
          //     }, 500)
          //   } else {
          //     this.showMsgDialog('warning', 'Document already returned', false)
          //     setTimeout(() => {
          //       this.selectedData = []
          //       this.actionValue = 0
          //     }, 200)
          //   }
          // } else {
          //   this.showMsgDialog(
          //     'warning',
          //     "Can't proceed this action, Invalid document status",
          //     false
          //   )
          //   setTimeout(() => {
          //     this.selectedData = []
          //     this.actionValue = 0
          //   }, 200)
          // }
          break
        case 2:
          alert('Cooming soon')
          break
      }
    },
    searchEnter() {
      this.initDataTable()
    },
    initDataTable() {
      this.itemEdit = null
      this.actionValue = 0
      this.selectedData = []
      axios
        .get(
          `${this.eSanqua}archive_document/loan/list?keyword=${this.paramAPI.keyword}&offset=&limit=&is_archived=&loan_start_date=&loan_end_date=&status=${this.paramAPI.status}`
        )
        .then(res => {
          this.result = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    rowClick(pValue) {
      alert('Cooming Soon')
      this.setLoaningDocId([pValue.id, pValue.doc.name])
      this.$router.push('/archive-management/document-release/detail')
    },
    release() {
      this.setLoaningDocumentName(['', null, null])
      this.$router.push('/archive-management/document-release/form-release')
    },
    convertDate(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)

        // switch (month) {
        //   case '01':
        //     month = 'JAN'
        //     break
        //   case '02':
        //     month = 'FEB'
        //     break
        //   case '03':
        //     month = 'MAR'
        //     break
        //   case '04':
        //     month = 'APR'
        //     break
        //   case '05':
        //     month = 'MAY'
        //     break
        //   case '06':
        //     month = 'JUN'
        //     break
        //   case '07':
        //     month = 'JUL'
        //     break
        //   case '08':
        //     month = 'AGS'
        //     break
        //   case '09':
        //     month = 'SEP'
        //     break
        //   case '10':
        //     month = 'OKT'
        //     break
        //   case '11':
        //     month = 'NOV'
        //     break
        //   case '12':
        //     month = 'DES'
        //     break
        // }

        // return day + ' ' + month + ' ' + year
        return (day + '-' + month + '-' + year).replace(/-/g, '/')
      }
    },

    countdown(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        const z = new Date(year + '/' + month + '/' + day)

        const a = new Date()
        const n = a.toLocaleDateString('id')
        const day2 = n.slice(0, n.indexOf('/'))
        const month2 = n.slice(n.indexOf('/') + 1, n.lastIndexOf('/'))
        const year2 = n.slice(n.lastIndexOf('/') + 1)
        const x = new Date(year2 + '/' + month2 + '/' + day2)

        const diffInTime = z.getTime() - x.getTime()
        const q = diffInTime / (1000 * 3600 * 24)

        return q
      }
    },

    downloadSoftCopy(pValue) {
      console.log(pValue)
      axios({
        url: `${this.download}esanqua/archive_document/edocument/${pValue.edoc_token}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${pValue.doc.doc_no}.pdf`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
    },
    status(raw) {
      if (raw.id === 8 || raw.id === 9) {
        return 'green'
      }
      if (raw.id === 9) {
        return 'black'
      }
      if (raw.id === 2) {
        return 'orange'
      }
      if (raw.id === 0 || raw.id === 7) {
        return 'red'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.docSpanDownload {
  border: 1px solid rgba(0, 0, 0, 0.568);
  font-weight: 500;
  border-radius: 3px;
  padding: 1px 2px;
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.384);
}
#requestList {
  margin-bottom: 10px;
  .request-list-div {
    padding: 40px 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .request-list-toolbar-1 {
      .request-list-toolbar-div {
        width: 50%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .request-list-toolbar-2 {
      display: none;
    }
  }
}

.arch-expDate {
  cursor: pointer;
  height: 40px;
  .arch-exp-date {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .arch-exp-date-div1 {
      overflow: hidden;
      width: 120px;
      position: absolute;
      left: 0;
      height: 40px;
      .arch-exp-date-notif {
        display: flex;
        height: 100%;
        // justify-content: center;
        align-items: center;
        // overflow: hidden;
        // width: 120px;
        // position: absolute;
        // left: 0;
        .exp-date-notif-div {
          display: flex;

          .exp-date-notif-chip1 {
            width: 95px;
            font-size: 10px;
          }
          .exp-date-notif-chip2 {
            font-size: 10px;
            .date-notif-chip2-p {
              width: 70px;
              margin: 0;
              margin: auto;
              text-align: center;
              // padding: 0 10px !important;
            }
          }
        }
      }
    }
  }

  &:hover .arch-exp-date-notif {
    width: 0;
    transition: 0.5s;
  }
}

@media (max-width: 768px) {
  #requestList {
    .request-list-div {
      .request-list-toolbar-1 {
        .request-list-toolbar-div {
          display: none;
        }
      }
      .request-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
